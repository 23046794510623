import { RouteStore, ROUTE_PARAMS } from "./shared";
import { Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import PrivacyPoliciesPage from "../pages/PrivacyPoliciesPage";
import TermsOfUserPage from "../pages/TermsofUsePage";
import ContactUsPage from "../pages/ContactUsPage";

export const ROOT_ROUTERS: RouteStore = {
    LANDING_PAGE: ( <Route key='landingPage' path={`/*`} element={<LandingPage />} /> ),
    LANDING_PAGE_SECTIONS: ( <Route key='landingPageSection' path={`/:sectionId`} element={<LandingPage />} /> ),
    PRIVACY_POLICIES_PAGE: ( <Route key='privacyPolicies' path={`/${ROUTE_PARAMS.PRIVACY_POLICIES}`} element={<PrivacyPoliciesPage />} /> ),
    TERMS_OF_USE_PAGE: ( <Route key='termsOfUser' path={`/${ROUTE_PARAMS.TERMS_OF_USE}`} element={<TermsOfUserPage />} /> ),
    CONTACT: ( <Route key='contactUs' path={`/${ROUTE_PARAMS.CONTACT}`} element={<ContactUsPage />} /> ),
};