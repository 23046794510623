import Stack from "@mui/material/Stack";
import styles from "./SeventhContent.module.scss";
import { LANDING_PAGE_PARAMS } from "../../../routers/shared";
import useGetScreenType from "../../../hooks/useGetScreenType";

const SeventhContent = () => {
    return (
        <Stack className={`${styles.seventhContent}`} id={LANDING_PAGE_PARAMS.ABOUT_US} >
            <Stack className={styles.textStack}>
                <h1 className={styles.h1}>We've come to shake things up.</h1>
                <big>
                    Coveryou is a digital accident replacement car service that 
                    covers the cost of your rental vehicle while your car is being repaired.
                    We have a cool technology, that allows you unlock and drive using your smartphone.
                    We're all about making it as easy, saving you time and covering your costs. 
                    We operate Australia wide, with offices in Melbourne, where our team and support staff work.
                </big>
            </Stack>
        </Stack>
    );
}

export default SeventhContent;
