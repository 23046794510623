import Stack from "@mui/material/Stack";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./index.module.scss";

const PrivacyPoliciesPage = () => (
  <Stack>
    <Header />
    <Stack className={`fillScreen ${styles.content}`}>
      <h3 className='bold'>Coveryou Privacy Policy</h3>
      <br />
      <p>
        This Privacy Policy sets out how Coveryou Pty Ltd protects the privacy
        of your personal information, your rights in relation to your personal
        information managed by us and the way in which we collect, hold, use and
        disclose your personal information. <br /> <br />
        In handling your personal information, Coveryou will comply with the
        Australian Privacy Act 1988, including the 13 Australian Privacy
        Principles), and all other relevant laws, regulations and codes relating
        to privacy and personal information (‘Privacy Law’). <br /> <br />
        Unless stated otherwise, this Privacy Policy is relevant to the personal
        information of individuals who are our current or former customers, as
        well as any other individuals we may deal with (for example, our
        customers’ guarantors, directors and shareholders or individuals we deal
        with in other capacities as part of our business). <br /> <br />
        This Privacy Policy may be updated from time to time. You should review
        it periodically so that you are aware of any changes.
      </p>
      <br />
      <h5 className='bold'>What kind of personal information do we collect?</h5>
      <br />
      <p>
        Personal information is information or an opinion about an identified,
        or reasonably identifiable, individual. <br /> <br />
        The kinds of personal information Coveryou collects and holds varies
        according to the specific product or service being provided and may
        include, but is not limited to, your:
      </p>
      <ol className={styles.oneTab}>
        <li>
          Contact and identification information, such as your name, address,
          email address, telephone number, gender and date of birth.
        </li>
        <li>
          Information about your assets, occupation and income, account
          balances, account activities, payment history and transactions with us
          or third parties.
        </li>
        <li>
          Government identifiers and related information, such as your driver’s
          licence, passport and visa particulars obtained, for example, to
          verify your identity or eligibility at the time you request a product
          or service.
        </li>
        <li>
          Other details relating to your relationship with us, including if we
          deal with you in a capacity other than a customer (for example,
          information about agreements or other arrangements or transactions you
          may have with us, billing information including your credit card
          details, your purchasing preferences and history, and any information
          contained in correspondence between us), and
        </li>
        <li>
          Any other information lawfully obtainable under relevant Privacy Law.
        </li>
      </ol>
      <p>
        In some circumstances, Coveryou may collect sensitive information about
        you, for example in relation to insurance claims. Sensitive information
        is a type of personal information and includes health information and
        information about racial or ethnic origin, political opinions,
        membership of a trade union or political association, religious beliefs
        or affiliations, philosophical beliefs, sexual preferences and criminal
        record. We will only collect sensitive information about you where we
        are permitted to do so by law.
      </p>{" "}
      <br />
      <h5 className='bold'>How do we collect your personal information?</h5>
      <br />
      <p>
        Where practicable we aim to collect your personal information directly
        from you. The circumstances in which we may do so will vary depending on
        the context in which we are dealing with you (for example, if you are a
        customer, according to the specific product or service we are providing)
        but can include, for example, when you are:
      </p>
      <ul className={styles.oneTab}>
        <li>
          Registering for one of our websites or services or logging in to use
          one of our websites.
        </li>
        <li>
          Submitting applications and other forms through our online services,
          by email, verbally and/or in hard copy.
        </li>
        <li>
          Subscribing to receive alerts/e-brochures and filling in surveys or
          research, or participating in promotions and competitions we offer.
        </li>
        <li>
          Contacting us for any reason including, but not limited to, reporting
          a problem with one of our services or websites, requesting further
          services, updating your personal information or otherwise seeking our
          assistance.
        </li>
        <li>Posting or contributing material on our websites, and</li>
        <li>
          Using our services and any credit provided by us in connection with
          those.
        </li>
      </ul>
      <p>
        There may be occasions when we collect your personal information from
        other sources, such as from:
      </p>
      <ul className={styles.oneTab}>
        <li>
          A public or commercial information services provider (such as
          providers of government, business, or credit information).
        </li>
        <li>
          Social media platforms, if you choose to interact with us via your
          social media account.
        </li>
        <li>
          A third party assisting us to process an application or other
          transaction, confirming information in an application or other request
          or assisting us to locate or communicate with you.
        </li>
        <li>
          Vehicle dealers and other businesses involved in dealings with you and
          us.
        </li>
        <li>
          Your employer, such as where we are providing a novated lease, salary
          packaging services, or fleet services.
        </li>
      </ul>
      <p>
        Generally, Coveryou will only collect your personal information from
        sources other than you if:
      </p>
      <ul className={styles.oneTab}>
        <li>
          It is unreasonable or impracticable to collect the personal
          information from you, and
        </li>
        <li>
          The collection of that personal information complies with all relevant
          Privacy Law.
        </li>
      </ul>
      <br />
      <h5 className='bold'>How we use your personal information</h5>
      <br />
      <p>
        We collect, hold, use, and disclose your personal information where it
        is reasonably necessary for the purposes of providing our products and
        services, for our other business purposes and for any necessary related
        purposes. These purposes include, but are not limited to:
      </p>
      <ol className={styles.oneTab}>
        <li>
          Provision of commercial and consumer vehicle leasing arrangements, and
          accident replacement vehicle services and emergency home assistance
          and roadside assistance products.
        </li>
        <li>Registering you to use one of our websites.</li>
        <li>
          Processing a product application or service or purchase request.
        </li>
        <li>
          Managing our products and services or other relationships and
          arrangements.
        </li>
        <li>
          Processing receipts, invoices and payments and servicing customer
          accounts, including determining liability for payment.
        </li>
        <li>
          Responding to customer inquiries about applications, accounts,
          products or services.
        </li>
        <li>
          Understanding customer needs and offering products and services to
          meet those needs.
        </li>
        <li>Assessing, processing and investigating risks or claims.</li>
        <li>Accounting, billing and other internal administrative purposes.</li>
        <li>
          Identifying and informing customers of products and services that may
          be of interest.
        </li>
        <li>Notifying you about changes to our products and services.</li>
        <li>
          Considering, and contacting you about, any application you make for a
          job with us.
        </li>
        <li>
          Any other legal and regulatory requirements. Various laws may
          expressly require us to collect your personal information, or we may
          need to do so to be able to comply with other obligations under those
          laws:
        </li>
        <br />
        <Stack className={styles.otherLegalReqItem}>
          <p>a.</p>
          <p>
            In Australia, such laws include the Anti-Money Laundering and
            Counter-Terrorism Financing Act 2006 (for example, to comply with
            identity verification requirements), the Personal Property
            Securities Act 2009 and State and Territory real property and
            security interests laws (for example, to register and search for
            security interests), the Financial Sector (Collection of Data) Act
            2001, the Corporations Act 2001 and other regulatory legislation
            (for example, requiring us to maintain client and transaction
            records, or to make reports and provide other information to
            regulators such as ASIC) and the Taxation Administration Act 1953,
            the Income Tax Assessment Act 1997 and other taxation laws (for
            example, to comply with information requests issued by the
            Commissioner of Taxation).
          </p>
        </Stack>
        <li>
          Enforcing our rights, including undertaking debt collection activities
          and legal proceedings; and
        </li>
        <li>Undertaking business funding and development activities.</li>
      </ol>
      <p>
        Where personal information is used and disclosed, we take steps
        reasonable in the circumstances to ensure the personal information is
        relevant to the purposes for which it is to be used and disclosed. You
        are under no obligation to provide your personal information to us.
        However, without certain information from you, we may not be able to
        provide our services to you or any other relevant customer.
      </p>
      <br />
      <br />
      <h5 className='bold'>Disclosures of your personal information</h5>
      <br />
      <p>
        We may disclose your personal information for purposes such as those
        listed above, and we will always do so consistently with our privacy
        obligations.
      </p>
      <br />
      <p>
        Third parties to whom we may disclose your personal information include:
      </p>
      <ol className={styles.oneTab}>
        <li>Our related entities in Australia and overseas.</li>
        <li>
          Administrative services providers, such as mailing houses, printers
          and call center operators.
        </li>
        <li>
          Legal, settlement, valuation, and recruitment service providers.
        </li>
        <li>Data processing and market research service providers.</li>
        <li>
          Regulatory bodies, law enforcement authorities, and toll operators in
          Australia and overseas.
        </li>
        <li>
          Any person who is considering whether to acquire or who has acquired
          any part of our business, or the rights or obligations under our
          contract with you.
        </li>
        <li>Financial and other advisors.</li>
        <li>
          Other participants in financial and payment systems, such as banks,
          credit providers, clearing entities, and credit card associations.
        </li>
        <li>
          Account information aggregation service providers (including Yodlee),
          to assist in the transfer of your account data to us in connection
          with an application for a vehicle leasing arrangement.
        </li>
        <li>
          Insurers, reinsurers, loss adjusters, assessors, and underwriters.
        </li>
        <li>Brokers and other distributors.</li>
        <li>Your guarantors and security providers.</li>
        <li>Debt collectors and investigators, and</li>
        <li>
          In connection with an application for a vehicle leasing arrangement,
          credit reporting bodies and other information providers.
        </li>
      </ol>
      <h5 className='bold'>Overseas Disclosures</h5>
      <br />
      <p>
        We may disclose your personal information to overseas recipients in
        order to provide our services and products and for administrative, data
        storage, or other business management purposes. It is not practicable to
        list all of the countries to which your information may be transmitted
        from time to time, but we transfer information between Australia and
        other countries that include the United States of America, the United
        Kingdom, the Philippines, Japan, Singapore, and Hong Kong.
      </p>
      <br />
      <p>
        Before disclosing any personal information to an overseas recipient, we
        take steps reasonable in the circumstances to ensure the overseas
        recipient complies with relevant Privacy Laws or is bound by a
        substantially similar privacy scheme to that which is in place in
        Australia, unless you specifically consent to the overseas disclosure
        taking place without such steps being taken, or the disclosure is
        otherwise required or permitted by law.
      </p>
      <br />
      <h5 className='bold'>Direct Marketing</h5>
      <br />
      <p>
        We may hold your personal information in physical form or in electronic
        form on our systems or the systems of our service providers.
      </p>
      <br />
      <p>
        We take steps reasonable in the circumstances to ensure that the
        personal information we hold is protected from misuse, interference, and
        loss and from unauthorized access, modification, or disclosure. We hold
        personal information in both hard copy and electronic forms in secure
        databases on secure premises, accessible only by authorized staff, and
        follow procedural safeguards that meet or exceed relevant Privacy Law.
      </p>
      <br />
      <p>
        We require third parties handling personal information on our behalf to
        follow equally compliant standards of security and confidentiality.
      </p>
      <br />
      <p>
        We will destroy or de-identify personal information in circumstances
        where it is no longer required unless we are otherwise required or
        authorized by law to retain the information.
      </p>
      <br />
      <h5 className='bold'>Access and Correction of Personal Information</h5>
      <br />
      <p>
        We take steps reasonable in the circumstances to ensure that personal
        information we hold is accurate, up-to-date, complete, relevant, and not
        misleading.
      </p>
      <p>
        You have a right to access and seek correction of your personal
        information that is collected and held by us. This is subject to some
        exceptions specified in the Privacy Act 1988 (Cth) (Australia).
      </p>
      <p>
        If at any time you would like to access or amend the personal
        information we hold about you, or you would like more information on our
        approach to privacy, please contact our Privacy Officer in accordance
        with the contact details set out below.
      </p>
      <p>
        We will grant access to personal information to the extent required by
        applicable Privacy Law and will take reasonable steps to amend personal
        information where necessary and appropriate.
      </p>
      <p>To obtain access to your personal information:</p>
      <ol className={styles.oneTab}>
        <li>
          You will have to provide proof of identity. This is necessary to
          ensure that personal information is provided only to the correct
          individuals and that the privacy of others is protected.
        </li>
        <li>
          We request that you be reasonably specific about the information you
          require; and
        </li>
        <li>
          We may charge you a reasonable administration fee which reflects the
          cost to us for providing access in accordance with your request. We
          will not charge you for making the request.
        </li>
      </ol>
      <p>
        If we refuse your request to access or correct your personal
        information, we will provide you with written reasons for the refusal
        and details of the ways in which you can make a complaint. We will also
        take steps reasonable in the circumstances to provide you with access to
        your personal information in a manner that meets yours and our needs.
      </p>
      <p>
        If you are dissatisfied with a refusal of a request to access or correct
        your credit information, you may make a complaint to the Australian
        Information Commissioner or to an applicable external dispute resolution
        scheme if the Coveryou entity you are dealing with is a member (we will
        provide details of any such scheme in our response to such a request for
        access or correction).
      </p>
      <p>
        In the event that we correct your personal information previously
        disclosed to another entity and you request that the other entity be
        notified of the correction, we will take reasonable steps in the
        circumstances to do so unless impracticable or unlawful.
      </p>
      <br />
      <h5 className='bold'>Complaints and Disputes</h5>
      <br />
      <p>
        If you wish to complain about the way in which we collect or handle your
        personal information, or if you believe we have breached any Privacy
        Law, you can contact our Privacy Officer using the details
        below.Following a complaint, our Privacy Officer will commence an
        investigation into your complaint. You will be informed of the outcome
        of your complaint following completion of the investigation. At all
        times, privacy complaints:
      </p>
      <ol className={styles.oneTab}>
        <li>Will be treated seriously;</li>
        <li>Will be dealt with promptly.</li>
        <li>Will be dealt with in a confidential manner; and</li>
        <li>
          Will not affect your existing obligations or impact on the commercial
          arrangements between you and us.
        </li>
      </ol>
      <p>
        In the event you are dissatisfied with the outcome of your complaint,
        you may refer the complaint to the Australian Information Commissioner
        or an applicable external dispute resolution scheme if relevant to the
        complaint and the Coveryou entity you are dealing with is a member (we
        will provide details of any such scheme in our response to such a
        request for access or correction).
      </p>
      <br />
      <h5 className='bold'>How to Contact Us</h5>
      <br />
      <p>
        Please direct all privacy queries and complaints to our Privacy Officer
        at:
      </p>
      <br />
      <p>
        Mail: Privacy Officer
        <br />
        <br />
        Coveryou
        <br />
        Level 8<br />
        1341 Dandenong Road
        <br />
        Chadstone, VIC 3148
      </p>
      <br />
      <p>
        Email:{" "}
        <a href='mailto:privacyofficer@coveryou.com.au'>
          privacyofficer@coveryou.com.au
        </a>
      </p>
    </Stack>
    <Footer />
  </Stack>
);

export default PrivacyPoliciesPage;
