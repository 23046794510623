import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { LANDING_PAGE_PARAMS } from "../../routers/shared";
import FifthContent from "./components/FifthContent";
import FirstContent from "./components/FirstContent";
import ForthContent from "./components/ForthContent";
import SecondContent from "./components/SecondContent";
import ThirdContent from "./components/ThirdContent";
import SixthContent from "./components/SixthContent";
import SeventhContent from "./components/SeventhContent";

const LandingPage = () => {
  const { sectionId } = useParams();

  useEffect(() => {
    let targetSection = document.querySelector(`#${sectionId}`);

    if (!targetSection) {
      targetSection = document.querySelector(`#${LANDING_PAGE_PARAMS.CONTACT}`);
    }

    (targetSection as Element).scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [sectionId]);

  return (
    <Stack>
        <Header />
        <Stack style={{ overflow:'hidden' }}>
            <FirstContent />
            <SecondContent />
            <ThirdContent />
            <ForthContent />
            <SeventhContent />
            <FifthContent />
            <SixthContent />
        </Stack>
        <Footer />
    </Stack>
  );
};

export default LandingPage;
