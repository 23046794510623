import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import "./App.css";
import LandingPage from "./pages/LandingPage";
import globalStyles from "./styles/_stylesParams.module.scss";
import "./styles/unScopeStyles.scss";
import { Routes, useLocation } from "react-router-dom";
import { ROOT_ROUTERS } from "./routers/rootRouters";
import DocumentMeta from "react-document-meta";
import { useMemo } from "react";
import { SEO_CONFIGS } from "./routers/shared";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-input": {
            padding: "10px 16px",
            color: globalStyles.shades100,
          },
          "& fieldset": {
            borderRadius: "8px",
          },
          "& .MuiFormHelperText-root": {
            marginLeft: "0px",
          },
          "& .Mui-error": {
            color: globalStyles.destructive500,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: ["none"],
          fontWeight: 400,
          padding: "0px",
          minWidth: "unset",
          ":hover": {
            backgroundColor: "unset",
          },
        },
      },
    },
  },
});

function App() {
  const location = useLocation();

  const meta = useMemo(() => {
    let seoConfig = SEO_CONFIGS[location.pathname];

    if (!seoConfig) {
      seoConfig = SEO_CONFIGS.DEFAULT;
    }

    return {
      description: seoConfig.des,
      title: seoConfig.title,
    };
  }, [location]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DocumentMeta {...meta} />
        <Routes>
          {Object.values(ROOT_ROUTERS).map((rootRouter) => rootRouter)}
        </Routes>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
