import Stack from "@mui/material/Stack";
import styles from "./ThirdContent.module.scss";
import a from "../../../assets/images/LandingPage/a.png";
import b from "../../../assets/images/LandingPage/b.png";
import c from "../../../assets/images/LandingPage/c.png";
import { LANDING_PAGE_PARAMS } from "../../../routers/shared";

interface ICard { imgUrl: string; title: string; subTitle?: string; }
const CONFIGS: ICard[] = [
    {
        imgUrl: a,
        title: "Customer Signup",
        subTitle: "Customer submit their application online, which includes providing their accident claim information.",
    },
    {
        imgUrl: b,
        title: "Insurance check",
        subTitle: "We verify your claim details so that you don’t end up paying unnecessarily for the hire.",
    },
    {
        imgUrl: c,
        title: "Prepare to drive",
        subTitle: "Once your claim is approved, you will be able to have the hire car dropped off to you.",
    },
];

const Card = ({ imgUrl, title, subTitle }: ICard) => (
    <Stack className={styles.card}>
        <img className={styles.icon} src={imgUrl} alt={title} />
        <h6 className={styles.cardTitle}>{title}</h6>
        {subTitle && <big className={styles.cardSubTitle}>{subTitle}</big>}
    </Stack>
);

const ThirdContent = () => (
    <Stack id={LANDING_PAGE_PARAMS.HOW_IT_WORKS} className={`${styles.thirdContent} fillScreen`} >
        <h1 className={styles.title}> How it Works </h1>
        <Stack className={styles.iconsStack} style={{marginTop: 32}}>
            {CONFIGS.map((config) => (
                <Card key={config.title} {...config} />
            ))}
        </Stack>
    </Stack>
);

export default ThirdContent;
