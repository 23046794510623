import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import styles from "./FirstContent.module.scss";
import firstContentLogo from "../../../assets/images/LandingPage/firstContentLogo.png";
import useGetScreenType from "../../../hooks/useGetScreenType";
import { LANDING_PAGE_PARAMS } from "../../../routers/shared";
import ApplyNowModal from "../../../components/CustomizedModal/applyNowModal";
import { useState } from "react";

const FirstContent = () => {
    const screenType = useGetScreenType();
    const [isApplyNowModal, setIsApplyNowModal] = useState(false);
    return (
        <>
            <ApplyNowModal isApplyNowModal={isApplyNowModal} handleClose={() => setIsApplyNowModal(false)} />
            <Stack spacing={4} id={LANDING_PAGE_PARAMS.CONTACT} direction={"row"} justifyContent={"center"} alignItems={"center"} className={`${styles.firstContent} fillScreen`} >
                <Stack className={styles.contentWrapper} >
                    <Stack className={styles.contentWrapperInside} >
                        <Stack className={styles.desStack}>
                            <Stack direction={screenType === "MOBILE" ? "column" : "row"} alignItems='center' >
                                <div className={styles.headerDes}>
                                <span style={{ color:'#005b76' }}>If you have been involved in an accident</span>
                                    <span style={{ color: '#333333'}}>, you may be entitled to </span>
                                    <span style={{ color: '#333333'}}>hire vehicle at no cost to you.</span>
                                    <span style={{ color: '#aeabab', fontSize: '0.875rem', fontWeight:'normal' }}>* T&C apply</span>
                                </div>
                            </Stack>
                            <h5 className='neutrals400' style={{ fontStyle:'normal !important', fontWeight:'300'}}>
                                We'll assist you in arranging a similar vehicle until yours gets repaired via our insurer-friendly mobility program.
                            </h5>
                        </Stack>
                        <Stack alignItems={"center"}>
                            <a href={process.env.REACT_APP_GETSTARTED ?? ''}><Button className={styles.getStartButton}> <h4 className='bold'>Get Started</h4> </Button></a>
                        </Stack>
                    </Stack>
                </Stack>
                <img src={firstContentLogo} alt='firstContentLogo' className={styles.firstContentLogo} />
            </Stack>
        </>
    );
};

export default FirstContent;
