import Stack from "@mui/material/Stack";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./index.module.scss";

const TermsOfUserPage = () => (
  <Stack>
    <Header />
    <Stack className={`fillScreen ${styles.content}`}>
      <>
        <h4 style={{ textAlign: "center" }}>TERMS & CONDITIONS OF USE</h4>
        <br />
        <br />
        <p>
          <strong>1.</strong>{" "}
          <strong>INTERPRETING YOUR RENTAL AGREEMENT</strong>
        </p>
        <br />

        <p>
          The Rental Agreement <strong>("Rental Agreement")</strong> between
          Coveryou Pty Ltd and You is made on the date shown on the Rental
          Document You have signed in respect of the Vehicle{" "}
          <strong>("Rental Document")</strong> , and is made up of that Rental
          Document and these Terms and Conditions. <br />
          In these Terms and Conditions:
        </p>
        <p>
          “Australian Consumer Law” means the Australian Consumer Law set out in
          Schedule 2 of the Competition and Consumer Act 2010 (cth) as amended
          or replaced from time to time. <br />{" "}
          <strong>"Authorised Driver / Joint Renter"</strong>
          means: <br />
          an additional driver who is noted on the Rental Agreement as an
          Authorised Driver or Joint Renter.
        </p>
        <p>Your spouse; Test</p>
        <p>
          Your employer for a fellow employee, if either is engaged in
          activities that are incidental to Your business duties.
        </p>
        <p>
          ‘Collection Costs’ means Coveryou Pty Ltd reasonable costs of
          collecting unpaid rental charges from You (including Coveryou Pty Ltd
          legal costs) and Coveryou Pty Ltd administration fee of $132.00 (incl
          GST) and its debt collection agents fee equal to 12% of the unpaid
          rental charges;
        </p>
        <p>
          ‘Excess Amount’ means the amount shown as Excess Amount on the Rental
          Agreement.
        </p>
        <p>
          ‘Excess Reduction’ means the product called Excess Reduction that You
          may purchase before the rental commences to reduce any excess amount
          payable;
        </p>
        <p>
          ‘Loss Damage Waiver’ means the loss damage waiver described on the
          Rental Agreement as LDW which reduces Your financial responsibility
          for loss damage to the Vehicle to the Excess Amount;
        </p>
        <p>
          ‘Overhead Damage’ means damage (excluding hail damage) to the Vehicle
          above the top of the door seal or the top of the front and back
          windscreens, or damage to the third party property, caused by the
          Vehicle coming into contact with anything overhanging or obstructing
          its path, objects being placed on the roof of the Vehicle, or You or
          any person standing or sitting on the roof of the Vehicle;
        </p>
        <p>
          {" "}
          <strong>"Coveryou Pty Ltd "</strong>
          means Coveryou Pty Ltd ABN 14 609 573 024 <br />{" "}
          <strong>‘Rental Charges’</strong>
          means the fees, costs, amounts and charges specified on the Rental
          Agreement.
        </p>
        <p>
          {" "}
          <strong>"Rental Period"</strong>
          means the period commencing on the date shown on the Rental Document
          and ending on the date that You return the Vehicle to Rental Car
          Company Name; <br /> <strong>"Substitute Vehicle Insurance"</strong>
          means a poliCoveryou Pty Ltd of motor vehicle insurance held by You or
          an Authorised Driver which covers You or the Authorised Driver while
          You or the Authorised Driver use the Vehicle as a substitute for the
          vehicle insured under that poliCoveryou Pty Ltd; <br />
          "Vehicle" means the vehicle described on the Rental Document (or any
          substitute vehicle), and includes its parts, components, accessories
          and contents supplied by Coveryou Pty Ltd;
        </p>
        <p>
          <strong>"You" or "Your"</strong>
          refers to the person(s) with whom the Rental Agreement is made.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>2.</strong> <strong>DRIVER</strong>
        </p>
        <br />

        <p>
          2.1 You agree, acknowledge and warrant that: <br />
          (a) only You or an Authorised Driver will drive the Vehicle; and{" "}
          <br />
          (b) You and any Authorised Driver are currently licensed to drive the
          Vehicle and have been so licenced to drive for a period of 12 months
          or longer (excluding any time under a learner's permit or a
          provisional licence); and
        </p>
        <p>(c) You and the Authorised Driver are not under 21 years age; and</p>
        <p>
          (d) You and the Authorised Driver have not had Your driver’s licence
          cancelled, endorsed or suspended within the last three years.
        </p>
        <br />

        <p>
          <strong>3. R</strong>
          <strong>EFERRAL</strong> <strong />
        </p>
        <br />

        <p>
          You acknowledge that where a person has referred or recommended You to
          Coveryou Pty Ltd, that person may receive a financial or non-financial
          benefit from Coveryou Pty Ltd.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>4. WHERE YOU CAN AND CANNOT DRIVE THE VEHICLE</strong>
        </p>
        <br />

        <p>
          4.1 You and any Authorised Driver must only use the Vehicle on a road,
          which is properly formed and constructed as a sealed, metalled or
          graded gravel road.
        </p>
        <p>
          4.2 You and any Authorised Driver must not, unless authorised in
          writing by Coveryou Pty Ltd, drive or take the Vehicle: <br />
          (a) to Kangaroo Island; however, if so authorised, You and any
          Authorised Driver must not drive the Vehicle between dusk and dawn
          outside the town limits;
        </p>
        <p>
          (b) to Fraser Island, Bruny Island, Groote Eylandt, to Gove Peninsula,
          Tiwi Islands, Stradbroke Islands, Moreton Island, or any island off
          the coast of Australia; <br />
          (c) into or out of the Northern Territory, Tasmania, or to any points
          in
          <br />
          Western Australia north of Carnarvon; <br />
          (d) in Queensland: <br />
          (1) on Highway No. 27 beyond Chillagoe in a Westerly direction; <br />
          (2) on Highway No. 1 beyond Normanton in a Southerly direction and no
          further North than Karumba; <br />
          (3) if the Vehicle is a passenger vehicle or truck, beyond Cooktown to
          the North or Lakeland to the West and no further than Cape Tribulation
          on the Coast Road;
        </p>
        <p>
          (4) on the Coast Road from Helenvale to Cape Tribulation, or from
          Laura to Lakeland unless the Vehicle is a Four Wheel Drive; <br />
          (e) in the snow (at anytime and anywhere (including Tasmania));
        </p>
        <p>(f) above the snow line in:</p>
        <p>(1) in New South Wales (being Jindabyne); or</p>
        <p>(2) Victoria (being Bright),</p>
        <p>From the beginning of June until the end of September;</p>
        <p>(g) on beaches or through streams, dams, rivers or flood waters;</p>
        <p>(h) in the Northern Territory:</p>
        <p>(1) on the Jim Jim Falls Road to Jim Jim Falls and Twin Falls; or</p>
        <p>(2) outside any town or city limits between dusk and dawn.</p>
        <p>(i) in Western Australia:</p>
        <p>(1) to any parts North of Carnarvon;</p>
        <p>
          (2) on the Gibb River, Cape Leveque Road, Widdjana Gorge, Canning
          Stock Route, Gunbarrel Highway and Hollard Track;
        </p>
        <p>
          (3) beyond 100 kilometres of the Perth city limits between dusk and
          dawn; or
        </p>
        <p>
          (4) otherwise, outside any town or city limits between dusk and dawn
        </p>
        <p>outside any city limits between dusk and dawn; or</p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>5. V</strong>
          <strong>EHICLE WEAR AND TEAR</strong> <strong />
        </p>
        <br />

        <p>
          5.1 Prior to handing over the Vehicle to You, Our staff will conduct
          an inspection of the Vehicle and prepare the Rental Form noting the
          fuel level, km’s and any existing damage to the Vehicle. You are
          required to take note of the condition of the Vehicle, identify any
          existing damage and immediately before moving the vehicle from its
          pickup point notify Coveryou Pty Ltd and provide images stamped with
          the date and time via email or the Rental Form application. <br />
          5.2 Coveryou Pty Ltd’s Wear and Tear Guide sets out what we deem to be
          acceptable and unacceptable wear and tear. <br />
          5.3 Coveryou Pty Ltd’s Wear and Tear Guide can be found at
          <a href='http://www.coveryou.com.au/wearandtear.pdf'> www.coveryou.com.au/wearandtear </a> or can be requested in hard copy.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>6.</strong> <strong>USE OF VEHICLE</strong>
        </p>
        <br />

        <p>
          6.1 You and any Authorised Driver must: <br />
          (a) not allow the Vehicle to be used for any illegal purpose, race,
          contest or performance test of any kind; <br />
          (b) not allow the Vehicle to be used to tow or push anything; <br />
          (c) not carry more passengers than may be properly accommodated by the
          seat belt restraints provided in the Vehicle, or carry a greater load
          than that for which it was built; <br />
          (d) not be under the influence of alcohol, drugs or have a blood
          alcohol content that exceeds the legal limit in the State or Territory
          in which the Vehicle is driven; <br />
          (e) not allow the Vehicle to be used to carry passengers for payment
          or reward of any kind; <br />
          (f) not use the Vehicle when it is damaged or unsafe; <br />
          (g) not use the Vehicle to transport goods, except in compliance with{" "}
          <br />
          all necessary approvals, permits, licences and government requirements
          (to be obtained at Your cost) and in accordance with the Vehicle
          manufacturer's and Coveryou Pty Ltd recommendations; <br />
          (h) not use the Vehicle for the conveyance or towing of any load which
          is incorrectly loaded or secured or is in excess of that for which the
          Vehicle was constructed; <br />
          (i) not, without Coveryou Pty Ltd prior written consent, use the
          Vehicle to carry any inflammable substance which has a flash point
          under 22.8°C or any other explosive or corrosive substances; and{" "}
          <br />
          (j) not use the Vehicle in contravention of any law. <br />
          6.2 You must pay for any unauthorised repairs to the Vehicle and for
          all
          <br />
          parking and traffic infringements in respect of the Vehicle during the{" "}
          <br />
          Rental Period.
        </p>
        <p>
          6.3 You and any Authorised Driver must not carry any animal or pet in
          the Vehicle.
        </p>
        <p>
          6.4 You and any Authorised Driver must not drive the Vehicle if
          Coveryou Pty Ltd has so directed You and any Authorised Driver.
        </p>
        <p>
          6.5 You and any Authorised Driver or any passenger must not smoke in
          the Vehicle.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>7. M</strong>
          <strong>AINTENANCE, SECURITY AND SAFTEY</strong>
          <strong />
        </p>
        <br />

        <p>
          7.1 You and any Authorised Driver must: <br />
          (a) maintain all of the Vehicle’s engine oils and engine coolant
          levels to the manufacturer’s specifications, as set out in the
          Vehicle’s operations manual located in the glove box or otherwise as
          required to maintain the Vehicle’s efficient performance; <br />
          (b) keep the Vehicle locked and the keys under Your or the Authorised{" "}
          <br />
          Driver’s personal control at all times; and <br />
          (c) comply with any applicable seat belt and child restraint laws.{" "}
          <br />
          7.2 You must not service the Vehicle or have repairs to the Vehicle
          carried out unless Coveryou Pty Ltd authorises You to do so. Coveryou
          Pty Ltd requires verification of the cost of repairs for audit and GST
          purposes. You should obtain an original tax invoice/receipt to assist
          Coveryou Pty Ltd. Coveryou Pty Ltd will reimburse You for any repairs
          to the vehicle authorised by it, provided that the cost of those
          repairs is verified to the extent that Coveryou Pty Ltd cannot verify
          the cost of repairs, Coveryou Pty Ltd will not reimburse You.
        </p>
        <p>
          7.3 You and Coveryou Pty Ltd acknowledge that the Vehicle is generally
          in an undamaged condition except as otherwise stated on the Rental
          Document.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>8. A</strong>
          <strong>CCIDENT DAMAGE</strong>
          <strong>, B</strong>
          <strong>REAKDOWN AND REPAIR</strong> <strong />
        </p>
        <br />

        <p>
          8.1 You must notify Coveryou Pty Ltd as soon as practicable and in any
          event within 24 hours: <br />
          (a) of any mechanical or structural fault on the Vehicle (including
          tyres, fluids, belts, hoses and lights) that You become aware of
          (including as indicated by any illuminated warning lights in the cabin
          of the Vehicle); or
          <br />
          (b) if the Vehicle is involved in a collision, it is damaged in any
          way, or if it breaks down. <br />
          8.2 In the event of a breakdown of the Vehicle, a 24 hour breakdown
          assist contact number is located inside the Vehicle at the top of its
          windscreen on the driver’s side. <br />
          8.3 You must not arrange to have the Vehicle towed or transported or
          arrange or undertake any repair of the Vehicle unless: <br />
          (a) it is through the breakdown assist contact number referred to in
          clause 8.2; <br />
          (b) We have authorised You to do so (and We will provide such
          authorisation if We consider it necessary to prevent further damage to
          the Vehicle, its theft, or loss by a third party); or <br />
          (c) in the case of an emergen Coveryou Pty Ltd and Our consent cannot
          reasonably be obtained. <br />
          8.4 We will not reimburse You for the cost of repair, towing or
          transport costs unless You have complied with clause 8.3 and You have
          produced all receipts and information as We may require. <br />
          8.5 In the event of a collision in which the Vehicle is damaged, if
          the other party leaves the accident scene without exchanging names and
          addresses or appears to be affected by alcohol or drugs, You must also
          immediately report the accident and damage to the police.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>9. V</strong>
          <strong>EHICLE RETURN</strong> <strong />
        </p>
        <br />

        <p>
          9.1 You must return the Vehicle to Coveryou Pty Ltd: <br />
          (a) to the place, on the date and by the time shown on the Rental
          Document (or sooner if required under clause 9.4);
        </p>
        <p>
          (b) in the same condition as it was at the commencement of the Rental
          Period, fair wear and tear (excepted under clause 5);
        </p>
        <p>
          (c) with a full tank of fuel (or otherwise pay to Coveryou Pty Ltd the
          cost to refuel the Vehicle).
        </p>
        <p>
          9.2 You must return the Vehicle to a Coveryou Pty Ltd location during
          our normal business hours. If You return the vehicle later than the
          time shown on the rental document, You must pay all additional rental
          charges. <br />
          9.3 If: <br />
          (a) You return the Vehicle on a date, or at a time, or to a place
          other than
          <br />
          that shown on the Rental Document; or <br />
          (b) any special conditions set out in the “Rates” section on the
          Rental Document are breached, the rates shown on the Rental Document
          will not apply and You must pay the Coveryou Pty Ltd standard rate for
          the Vehicle for the Rental Period. <br />
          9.4 Coveryou Pty Ltd may request the immediate return of the Vehicle,
          or Coveryou Pty Ltd may re-take the Vehicle without notice, if
          Coveryou Pty Ltd reasonably suspects that: <br />
          (a) You have breached a term or condition of the Rental Agreement;{" "}
          <br />
          (b) damage to the Vehicle, or injury to persons or property is likely
          to
          <br />
          occur; or <br />
          (c) the Vehicle will be involved in an industrial dispute; or <br />
          (d) the Vehicle may be used for an unlawful purpose; <br />
          You must also pay Coveryou Pty Ltd any cost it incurs as well as all
          costs and charges under the Rental Agreement for the period up to
          return/repossession of the Vehicle.
        </p>
        <p>
          9.5 Coveryou Pty Ltd reserves the right to refuse hire of another
          vehicle to You following any incident or accident or where You have
          breached a condition of this Rental Agreement.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>10. F</strong>
          <strong>UEL</strong> <strong />
        </p>
        <br />

        <p>
          10.1 You agree to return the Vehicle with the same amount of fuel as
          indicated on the Rental Form at the commencement of the Hire Period.{" "}
          <br />
          10.2 Should You fail to do so You agree to pay Coveryou Pty Ltd for
          the fuel required to return the fuel level to the original fuel level
          and any refuelling surcharge. <br />
          10.3 You must only use fuel prescribed for the Vehicle as identified
          in the Rental Form. <br />
          10.4 You agree to pay all costs of any resulting loss or damage to the
          Vehicle resulting from the use of incorrect fuel grade or type.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>11.</strong>
          <strong>LOSS DAMAGE WAIVER, DAMAGE AND LOSS OF PROPERTY</strong>
        </p>
        <br />

        <p>
          11.1 Subject to this clause 11,and to the fullest extent permitted by
          applicable law, You are liable:
          <br />
          (a) for the loss of, and all damage to, the Vehicle; and
          <br />
          (b) for all damage to the property of any person:
          <br />
          (i) which is caused or contributed to by You or an Authorised Driver;
          or
          <br />
          (ii) which arises from the use of the Vehicle by You or an Authorised
          Driver.
          <br />
          This clause 11 does not apply to any damage or loss for which Coveryou
          Pty Ltd is liable to You under this Rental Agreement.
          <br />
          <em>
            Remember that references to the <strong>"Vehicle"</strong> include
            all of its parts, components, accessories and contents (see the
            definition of "Vehicle" in clause 1).
          </em>
          <br />
          11.2 Subject to clause 11.3, if: <br />
          (a) You accept the Loss Damage Waiver option on the Rental Document at
          the commencement of the Rental Period; and <br />
          (b) where applicable, You pay the excess shown on the Rental Document
          for each separate event involving damage to or loss of, the Vehicle or
          for each separate event involving damage to the property of any third
          party which is caused by or arises from the use of the Vehicle by You
          or an Authorised Driver, Coveryou Pty Ltd: <br />
          (c) waives Your liability under clause 11.1 for damage to the Vehicle
          or loss of the Vehicle; <br />
          (d) provided that You and any Authorised Driver are entitled to be
          indemnified under a policy of motor vehicle insurance provided by a
          registered insurer for Your and an Authorised Driver’s legal liability
          to a third party for damage to the property of that third party which
          is caused by the use of the Vehicle by You or an Authorised Driver.{" "}
          <br />
          11.3 You must always pay, and clause 11.2 does not cover: <br />
          (a) the excess shown on the Rental Document if there is damage to or
          loss of the Vehicle or if there is damage to the property of any third
          party; <br />
          (b) the cost of rectifying any tyre damage not attributable to normal
          wear and tear; <br />
          (c) the cost of repairing any damage caused deliberately or recklessly
          by:
          <br />
          (i) You; <br />
          (ii) any other driver of the Vehicle; or <br />
          (iii) any passenger carried during the Rental Period; <br />
          (d) the cost of repairing any damage to the Vehicle or to third party
          property caused by You or an Authorised Driver using, or permitting
          the Vehicle to be used, <br />
          in any area prohibited by the Rental Agreement; <br />
          (e) the cost of repairing overhead or roof damage caused by, but not
          limited to, contact between the Vehicle and objects overhanging or
          obstructing the path of the Vehicle; or <br />
          (f) the cost of repairing any water damage to the Vehicle or any
          underbody damage, and any resulting damage from that underbody damage,
          to the Vehicle.
        </p>
        <p>
          (g) under any circumstances where the Vehicle has been refuelled with
          fuel other than that recommended by the Vehicle manufacturer.
        </p>
        <p>
          (h) under any circumstances where the Vehicle and its keys are
          unsecured
          <br />
          11.4 For the purposes of this clause 11, You must pay for any damage
          or repair that may be reasonably determined by Coveryou Pty Ltd the
          amount which includes: <br />
          (a) the cost of repairs to the Vehicle or the market value of the
          Vehicle
          <br />
          at the time of the loss or damage, whichever is the lesser; <br />
          (b) appraisal fees; <br />
          (c) towing, storage and recovery costs; <br />
          (d) a reasonable administrative fee reflecting the cost of making
          arrangements <br />
          for repairs and towing and other administrative activities; and <br />
          (e) a per day loss of use fee based on the estimated downtime of the{" "}
          <br />
          Vehicle. <br />
          If the amount determined by Coveryou Pty Ltd and paid by You under
          this clause 11.4 exceeds the final cost of the damage or repair,
          Coveryou Pty Ltd will refund the difference to You.
        </p>
        <br />

        <p>
          <strong>12. T</strong>
          <strong>
            OLLS, TRAFFIC AND PARKING FINES AND INFRINGEMENTS
          </strong>{" "}
          <strong />
        </p>
        <br />

        <p>
          12.1 If the Vehicle travels through a toll collection point, You are
          liable for the applicable amount (which is an Additional Charge)
          together with an administration fee (which is a Hire Charge) to cover
          Our cost. <br />
          12.2 You are responsible for all traffic and parking fines and
          infringements (which are Additional Charges) for the duration of the
          Hire Period. If Coveryou Pty Ltd is notified of a fine or an
          infringement during or after the Hire Period, Coveryou Pty Ltd will
          provide necessary details to the notifying authority to nominate You
          as the driver liable for the infringement. Coveryou Pty Ltd may charge
          an administration fee (which is a Hire Charge) to cover its costs in
          providing these details.
        </p>
        <br />

        <p>
          <strong>13.</strong>
          <strong>LIABILITY OF COVERYOU PTY LTD</strong>
          <strong></strong>
        </p>
        <br />

        <p>
          13.1 Unless it is negligent, Coveryou Pty Ltd is not liable to any
          person, and You indemnify Coveryou Pty Ltd, for any loss of, or damage
          to, any property: <br />
          (a) stolen from the Vehicle or otherwise lost during the rental; or{" "}
          <br />
          (b) left in the Vehicle after its return to Coveryou Pty Ltd.
        </p>
        <br />

        <p>
          <strong>14. CONSUMER RIGHTS STATEMENT</strong> <strong />
        </p>
        <br />

        <p>
          All Your rights set out in this Rental Agreement are in addition to
          Your rights as a consumer under applicable consumer protection
          legislation, including the Australian Consumer Law.
        </p>
        <p>
          Your consumer rights are not excluded, modified, or restricted by this
          Rental Agreement. You can find out more about your consumer rights
          from consumer organisations and bodies such as the Australian
          Competition and Consumer Commission and State or Territory fair
          trading authorities.
        </p>
        <br />

        <p>
          <strong>1</strong>
          <strong>5</strong>
          <strong>. C</strong>
          <strong>LAIMS AND PROCEEDINGS</strong> <strong />
        </p>
        <br />

        <p>
          15.1 Where the use of the Vehicle by You, an Authorised Driver, or any
          other person results in an accident or claim, or where damage or loss
          is sustained to the Vehicle or any third party property, You and/or
          any Authorised Driver must: <br />
          (a) promptly report such incident to the local police; <br />
          (b) promptly report such incident in writing to Coveryou Pty Ltd;{" "}
          <br />
          (c) not, without Coveryou Pty Ltd written consent, make or give any
          offer, promise of payment, settlement, waiver, release, indemnity or
          admission of liability; <br />
          (d) permit Coveryou Pty Ltd or its insurer at its own cost to bring,
          defend, enforce or settle any legal proceedings against a third party
          in Your name;
          <br />
          (e) permit or ensure that Coveryou Pty Ltd may claim in Your name or
          that of the Authorised Driver under any applicable Substitute Vehicle
          Insurance, and assist, and cause the Authorised Driver to assist,
          Coveryou Pty Ltd in making such a claim, including assigning any right
          to claim under any Substitute Vehicle Insurance to Coveryou Pty Ltd;{" "}
          <br />
          (f) complete and furnish to Coveryou Pty Ltd within a reasonable time
          any statement, information or assistance which Coveryou Pty Ltd or its
          insurer may reasonably require, including attending at a lawyer's
          office and at Court to give evidence. <strong />
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>1</strong>
          <strong>6</strong>
          <strong>. P</strong>
          <strong>AYMENT AND CHARGES</strong> <strong />
        </p>
        <br />

        <p>
          16.1 At the end of the Rental Period, You must pay Coveryou Pty Ltd on
          demand: <br />
          (a) all charges specified on the Rental Document and all charges
          payable under the Rental Agreement; <br />
          (b) any amount paid or payable by Coveryou Pty Ltd or You to any
          person arising out of Your use of the Vehicle or imposed on You or
          Coveryou Pty Ltd by any governmental or other competent authority
          (such as speeding, parking and traffic fines and toll charges); and{" "}
          <br />
          (c) any amount for which You are liable to Coveryou Pty Ltd under the
          Rental Agreement, in respect of a breach of the Rental Agreement or
          otherwise.
          <br />
          16.2 The minimum charge You must pay for the rental of the Vehicle is
          an amount equivalent to: <br />
          (a) one day's rental at the "daily rate" shown on the Rental Document
          (subject to clause 6.4); plus <br />
          (b) the amount payable for the number of kilometres driven during the
          Rental Period. <br />
          16.3 Distance charges are measured from the Vehicle's odometer. <br />
          16.4 You authorise Coveryou Pty Ltd to charge all moneys payable to
          Coveryou Pty Ltd under the Rental Agreement to Your credit card or
          charge account.
          <br />
          16.5 Coveryou Pty Ltd will pay any refund due to You by such method as
          Coveryou Pty Ltd may reasonably choose.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>1</strong>
          <strong>7</strong>
          <strong>. T</strong>
          <strong>ERMINATION OF AGREEMENT</strong> <strong />
        </p>
        <br />

        <p>
          17.1 Our right to terminate <br />
          We may immediately terminate the Agreement if We are of the reasonable
          opinion that: <br />
          (a) there has been a Serious Breach; <br />
          (b) You have made any admission of liability in respect to the
          Accident;
          <br />
          (c) You have breached the Agreement (including by failing to cooperate
          and provide (and procure that the driver and/or owner of the Damaged
          Vehicle at the time of the Accident provides) all reasonable
          assistance to Coveryou Pty Ltd in respect to the implementation and
          conduct of the recovery process), except to the extent such breach is
          a Serious Breach or is caused by circumstances outside the reasonable
          control of the Hirer; or <br />
          (d) the Hire Charges will not be fully recoverable from the Third
          Party for any reason whatsoever. <br />
          17.2 Your right to terminate <br />
          You may immediately terminate the Agreement if We have breached its
          terms and failed to remedy such breach within twenty (20) working days
          of receiving notice of such breach from You. <br />
          17.3 Consequences <br />
          (a) If the Agreement is terminated in accordance with clause 17.1(a),
          then:
          <br />
          (i) We may take immediate possession of the Vehicle; <br />
          (ii) You must pay all costs to replace or rectify the Vehicle to its
          state and condition as at the commencement of the Hire Period; <br />
          (iii) You must pay all reasonable costs for towing, storage and
          recovery of the Vehicle; and <br />
          (iv) any unrecoverable Hire Charges and the Additional Charges will
          become immediately due and payable by the Hirer to Coveryou Pty Ltd.{" "}
          <br />
          (b) If the Agreement is terminated in accordance with clause 17.1(b)
          or 17.1(c) (other than due to a breach by the Hirer of clause 2.1(b)
          of the Agreement and Authority to Act), then: <br />
          (i) any unrecoverable total Hire Charges (including where the Hirer’s
          failure to cooperate results in a settlement for a reduced sum with
          the Third Party); and <br />
          (ii) the Additional Charges, <br />
          will become immediately due and payable by the Hirer to Coveryou Pty
          Ltd.
          <br />
          (c) If the Agreement is terminated in accordance with clause 17.1(c)
          due to a breach by the Hirer of clause 2.1(b) of the Agreement and
          Authority to Act, then: <br />
          (i) the total Hire Charges for any day or days after the repairs were
          completed or after the Hirer received notification of a total loss
          settlement; plus <br />
          (ii) the Additional Charges, <br />
          will become immediately due and payable by the Hirer to Coveryou Pty
          Ltd.
          <br />
          (d) If the Agreement is terminated in accordance with clause 17.1(d),
          only the Additional Charges will become immediately due and payable by
          the Coveryou Pty Ltd.
        </p>
        <br />

        <p>
          <strong>1</strong>
          <strong>8</strong> <strong>. MISCELLANEOUS</strong>
        </p>
        <br />

        <p>
          18.1 The Rental Agreement, Agreement &amp; Authority to Act contains
          the whole agreement between the parties.
        </p>
        <p>
          18.2 Any waiver by a party of any one breach or default by the other
          party will not constitute a waiver of any other breach or default.
        </p>
        <p>
          18.3 The agreement is governed by the law of the State or Territory of
          the Rental location. The parties submit to the non-exclusive
          jurisdiction of the courts of that State or Territory and any courts
          which may hear appeals from those courts in respect of any proceedings
          in connection with this agreement. The parties will not object to the
          exercise of jurisdiction by those courts on any basis.
        </p>
        <br />

        <p>
          <strong>1</strong>
          <strong>9</strong>
          <strong>. PRESUMPTIONS AND INTERPRETATION</strong>
        </p>
        <br />

        <p>19.1 Unless the context otherwise requires:</p>
        <p>
          (a) A word which denotes the singular denotes the plural and vice
          versa;
        </p>
        <p>(b) Any gender denotes the other genders; and</p>
        <p>
          (c) A person includes an individual, a body corporate and a government
          body.
        </p>
        <p>19.2 Unless the context otherwise requires, a reference to:</p>
        <p>
          (a) Any legislation includes any regulation or instrument made under
          it and where amended, re-enacted or replaced means that amended,
          re-enacted or replaced legislation;
        </p>
        <p>
          (b) Any other agreement or instrument, where amended or replaced,
          means that agreement or instrument as amended or replaced.
        </p>
        <br />

        <p>
          <strong>20</strong>
          <strong>. D</strong>
          <strong>ISPUTE RESOLUTION</strong>
          <strong />
        </p>
        <br />

        <p>
          20.1 If You wish to dispute a charge or otherwise make a claim against
          Coveryou Pty Ltd, You agree to first notify Coveryou Pty Ltd in
          writing of the nature of the dispute or claim and provide Coveryou Pty
          Ltd with such particulars and evidence as We reasonably require.{" "}
          <br />
          20.2 You agree to discuss and negotiate Your dispute or claim in good
          faith with Coveryou Pty Ltd, within ten (10) working days of giving
          Coveryou Pty Ltd notice of Your dispute or claim.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>21</strong>
          <strong>.</strong>
          <strong>PRIVACY STATEMENT AND CONSENT</strong>
          <strong />
        </p>
        <br />

        <p>
          21.1 Purposes for collecting, disclosing and using Personal
          Information
          <br />
          (a) Coveryou Pty Ltd may collect, disclose and use the Hirer’s
          Personal Information for the purposes set out in this clause 21.{" "}
          <br />
          (b) Coveryou Pty Ltd collects, discloses and uses the Hirer’s Personal
          Information in order to provide the Hirer with its accident
          replacement vehicle services in connection with the Agreement. <br />
          (c) Coveryou Pty Ltd may also collect, disclose and use the Hirer’s
          Personal Information in connection with the Agreement for the
          following purposes:
          <br />
          (i) to assess and process the Hirer’s application for an accident
          replacement vehicle; <br />
          (ii) to facilitate claims on the Hirer’s behalf for the costs of
          Coveryou Pty Ltd’s services with the Third Party (including by
          disclosing the Hirer’s Personal Information to such persons); <br />
          (iii) to execute instructions; <br />
          (iv) to manage Coveryou Pty Ltd’s relationship with the Hirer (whether
          online or in person); <br />
          (v) to comply with legislative or regulatory requirements; <br />
          (vi) to identify the Hirer; and <br />
          (vii) for any internal processes including claims assessments, product
          development, strategic planning, risk management and pricing. <br />
          (d) Coveryou Pty Ltd may also collect, disclose and use the Hirer’s
          Personal Information (unless the Hirer tells Coveryou Pty Ltd not to)
          to identify or contact the Hirer to discuss other products and
          services that could be of interest to the Hirer or the company it
          represents. <br />
          (e) The Hirer acknowledges that if it does not provide Coveryou Pty
          Ltd with its Personal Information, Coveryou Pty Ltd may be unable to
          provide the Hirer with its services or deal with any insurer on the
          Hirer’s behalf.
          <br />
          21.2 Providing information about another person <br />
          When the Hirer gives Coveryou Pty Ltd Personal Information about
          another person, other than the Third Party: <br />
          (a) the Hirer represents that they are authorised to do so; and <br />
          (b) the Hirer agrees to inform the person that Coveryou Pty Ltd has
          collected their Personal Information as part of this Agreement and of
          the contents of this clause 21. <br />
          21.3 Persons with whom Personal Information is disclosed <br />
          (a) Coveryou Pty Ltd may disclose the Agreement or the Hirer’s
          Personal Information with any relevant insurer(s) in order to
          facilitate a claim on the Hirer’s behalf for the costs associated with
          hiring an accident replacement vehicle from Coveryou Pty Ltd. <br />
          (b) Coveryou Pty Ltd may also disclose the Hirer’s Personal
          Information to:
          <br />
          (i) Coveryou Pty Ltd’s related bodies corporate, agents, contractors,
          service providers or external advisers; <br />
          (ii) any other persons related to the Accident (including the police);{" "}
          <br />
          (iii) insurers, reinsurers, loss adjusters, assessors and
          underwriters;
          <br />
          (iv) any organisation responsible for the processing of traffic
          related infringements or road tolls, to the extent that these fees
          apply to the Vehicle; <br />
          (v) investigators; <br />
          (vi) any person Coveryou Pty Ltd considers necessary to execute the
          Hirer’s instructions; <br />
          (vii) any person who is considering whether to acquire or who has
          acquired any part of Coveryou Pty Ltd’s business, or the rights or
          obligations under the Agreement; <br />
          (viii) law enforcement, regulatory and government bodies as permitted
          or required by law in Australia or overseas; and <br />
          (ix) overseas recipients who provide services and products to Coveryou
          Pty Ltd and for administrative, data storage or other business
          management purposes. It is not practicable to list all of the
          countries to which the Hirer’s information may be transmitted from
          time to time, but the Hirer’s Personal Information may be transmitted
          between Australia, the United Kingdom, the Philippines, India and
          other countries. <br />
          (c) Coveryou Pty Ltd may also disclose the Hirer’s Personal
          Information (unless the Hirer tells Coveryou Pty Ltd not to) to any
          other persons (including Coveryou Pty Ltd’s related bodies corporate,
          agents, contractors, service providers, external advisers and third
          parties) to identify or contact the Hirer to discuss other products
          and services that could be of interest to the Hirer or the company it
          represents. <br />
          (d) The Hirer acknowledges that: <br />
          (i) it consents to Coveryou Pty Ltd collecting, disclosing and using
          the Hirer’s Personal Information for the purposes set out in this
          clause 21; and
          <br />
          (ii) if Coveryou Pty Ltd discloses the Hirer’s Personal Information to
          any other person for the purposes set out in this clause 21, Coveryou
          Pty Ltd will, to the extent permitted by law, not be accountable under
          the Privacy Act 1988 (Cth) for any misuse, interference or loss or
          unauthorised access, modification or disclosure of the Personal
          Information by that other person.
          <br />
          21.4 Hirer’s rights <br />
          (a) The Hirer has the right to: <br />
          (i) request access and corrections to its Personal Information held by
          Coveryou Pty Ltd; and <br />
          (ii) make a complaint about the way in which Coveryou Pty Ltd has
          managed the Personal Information. <br />
          (b) Coveryou Pty Ltd’s Privacy Policy sets out how to make an access
          request or complaint, and how such requests or complaints are handled.{" "}
          <br />
          (c) Coveryou Pty Ltd’s Privacy Policy can be found at{" "}
          <a href='https://coveryou.com.au/privacy'>
            https://coveryou.com.au/privacy
          </a> or can be requested in hard copy. <br />
          (d) The Hirer may also gain access to, seek correction of, or to
          complain about the handling of Personal Information that Coveryou Pty
          Ltd holds about the Hirer by contacting Coveryou Pty Ltd’s Privacy
          Officer at: <br />
          Privacy Officer,
        </p>
        <p>
          Coveryou Pty Ltd <br />
          Tower 1, Level 8, 1341 Dandenong Road <br />
          Chadstone VIC 3148, Australia <br />
          or by email to:{" "}
          <a href='mailto:privacyofficer@coveryou.com.au'>
            privacyofficer@coveryou.com.au
          </a>
        </p>
        <br />
        <br />
        <p style={{ textAlign: "center" }}>
          <strong>AGREEMENT AND AUTHORITY TO ACT</strong>
        </p>
        <p>
          Parties <br />
          Between Coveryou Pty Ltd ACN 609 573 02 of Tower 1, Level 1, 1341
          Dandenong Road, Chadstone VIC 3148 (Coveryou Pty Ltd) and the hirer
          (Hirer). <br />
          <br />
          <strong>B</strong>
          <strong>ACKGROUND</strong>
          <strong />
          <strong></strong>
        </p>
        <p>
          The Accident was a result of the negligence of the Third Party and the
          Hirer is seeking to recover the costs of a replacement vehicle for the
          period the Damaged Vehicle was unavailable for their use. The Hirer is
          aware that they are entitled to hire a replacement vehicle and expects
          to be reimbursed in full for the replacement vehicle costs. The Hirer
          authorises Coveryou Pty Ltd, its nominated management service, debt
          recovery agents and legal representatives to recover these costs on
          their behalf and the Hirer requires all monies owed in relation to
          this car hire to be paid to Coveryou Pty Ltd.
        </p>
        <br />

        <p>
          <strong>1. D</strong>
          <strong>EFINITIONS</strong> <strong />
        </p>
        <br />

        <p>
          Unless the context requires otherwise, capitalised words in this
          Agreement and Authority to Act have the meaning given to them in
          clause 1 (Definitions) of the terms &amp; conditions of use
          accompanying this Agreement and Authority to Act.
        </p>
        <br />

        <p>
          <strong>2. N</strong>
          <strong>
            ATIONAL CONSUMER CREDIT CODE AND FINANCIAL SERVICES
          </strong>{" "}
          <strong />
        </p>
        <br />

        <p>
          The Agreement is not regulated by the National Consumer Credit
          Protection Act 2009 (Cth) (including the National Credit Code at
          Schedule 1) or Chapter 7 of the Corporations Act 2001 (Cth). It deals
          with the Hirer’s obligations with respect to the Vehicle issued as a
          result of a road traffic incident, including to appoint Coveryou Pty
          Ltd to recover the Hire Charges from the Third Party in accordance
          with the Hirer’s common law rights. The parties acknowledge and agree
          that Coveryou Pty Ltd will not be providing the Hirer any claims
          handling and settling services for insurance products. The Hirer
          should read the Agreement, and all accompanying documentation,
          carefully before signing.
        </p>
        <br />

        <p>
          <strong>3. R</strong>
          <strong>ECOVERY OF HIRE CHARGES</strong>
          <strong></strong>
        </p>
        <br />

        <p>
          <strong />
        </p>
        <p>
          <strong>3.1 H</strong>
          <strong>IRE CHARGES</strong> <strong /> <br />
          (a) The Hirer agrees to cooperate and provide (and procure that the
          driver and/or owner of the Damaged Vehicle at the time of the Accident
          provides) all reasonable assistance to Coveryou Pty Ltd and its
          nominated management service, debt recovery agents and legal
          representatives in recovering the Hire Charges from the Third Party,
          to act in the best interests of Coveryou Pty Ltd and not in any way
          delay or hold up the timely recovery of the Hire Charges. <br />
          (b) Subject to clause 16 (Termination of Agreement) of the Terms &amp;
          Conditions of Coveryou Pty Ltde and on the condition that the Hirer
          fulfils their obligations under the Agreement (including under clause
          2), upon recovery of the Hire Charges from the Third Party by Coveryou
          Pty Ltd the Hirer will be released from liability for the Hire
          Charges, except for the Additional Charges for which the Hirer shall
          remain liable. The parties acknowledge and agree that this release
          shall constitute sufficient consideration for the obligations of the
          Hirer under this Agreement and Authority to Act. <br />
          (c) The parties acknowledge that, as at the date of the Agreement, the
          Hire Charges are only estimated because the Hirer is unable to advise
          the duration of the repairs to or the replacement of the Damaged
          Vehicle. The final Hire Charges will not be known until expiry of the
          Hire Period. <br />
          <br />
          <strong />
        </p>
        <p>
          <strong>3.2 A</strong>
          <strong>UTHORISATION</strong> <strong /> <br />
          The Hirer authorises Coveryou Pty Ltd, its nominated management
          service, debt recovery agents and legal representatives to: <br />
          (a) act on the Hirer’s behalf in respect of the recovery of the Hire
          Charges from the Third Party; <br />
          (b) ask for any documents that may be required from the repairer of
          the Damaged Vehicle to enable prompt settlement of the Hire Charges;{" "}
          <br />
          (c) receive monies and bank relevant cheques made payable to the Hirer
          into Coveryou Pty Ltd’s account or any other account nominated by
          Coveryou Pty Ltd; and <br />
          (d) utilise and apply any monies or relevant cheques received in
          settlement or payment of the Hirer’s claim against the Third Party to
          discharge any outstanding Hire Charges owed by the Hirer to Coveryou
          Pty Ltd, subject to Coveryou Pty Ltd accounting to the Hirer for any
          surplus. <br />
          <br />
        </p>
        <p>
          <strong>3.3 A</strong>
          <strong>PPOINTMENT</strong>
          <strong />
          <strong>
            <br />
          </strong>
          The Hirer irrevocably appoints Coveryou Pty Ltd, its nominated
          management service, debt recovery agents and legal representatives as
          the Hirer’s agents, representatives and duly authorised attorneys, to
          recover the Hire Charges by whatever means necessary. <br />
          <br />
          <strong />
        </p>
        <p>
          <strong>3.4 R</strong>
          <strong>ECOVERY PROCESS</strong> <strong />
          <br />
          (a) The Hirer understands and accepts that Coveryou Pty Ltd may refer
          the matter to its nominated legal representatives on behalf of the
          Hirer in recovering the Hire Charges and the Hirer agrees to provide
          any reasonable assistance requested by Coveryou Pty Ltd in respect of
          such recovery process, which the Hirer acknowledges may require the
          Hirer to (amongst other things): <br />
          (i) sign any certificate pursuant to the Civil Procedure Act 2010
          (Vic) or equivalent legislation; <br />
          (ii) provide statements and documents; and <br />
          (iii) assist with legal proceedings, which may include attending a
          lawyer’s office or appearing in court as a witness or to give evidence
          (upon reasonable notice to do so). <br />
          (b) Coveryou Pty Ltd indemnifies the Hirer for all costs and
          disbursements incurred by the legal representatives in the recovery
          process, provided that: <br />
          (i) the Hirer has complied with the terms of the Agreement; and <br />
          (ii) all warranties given by the Hirer in the Agreement are true and
          correct.
        </p>
        <br />

        <p>
          <strong>4. H</strong>
          <strong>IRER’S OBLIGATIONS</strong>
        </p>
        <br />

        <p>
          <strong>4.1 I</strong>
          <strong>NFORMATION</strong>
          <strong>
            {" "}
            <br />
          </strong>
          The Hirer agrees to: <br />
          (a) provide to Coveryou Pty Ltd all documentation required for
          Coveryou Pty Ltd to claim the Hire Charges on the Hirer’s behalf
          (including the Hirer’s driver’s licence and the driver’s licence of
          the person driving the Damaged Vehicle at the time of the Accident);
          and <br />
          (b) be open and frank with Coveryou Pty Ltd and not provide any
          information to Coveryou Pty Ltd or the Third Party which is untrue or
          misleading or has the potential to mislead. <br />
          <br />
          <strong />
        </p>
        <p>
          <strong>4.2 C</strong>
          <strong>LAIM AND SETTLEMENT</strong> <strong />
        </p>
        <p>
          The Hirer agrees to: <br />
          (a) lodge a claim with the Hirer’s insurer (if applicable) or the
          Third Party as soon as practicable after the Accident, regularly
          follow up the claim, assessment and repair process and provide
          Coveryou Pty Ltd with such information as Coveryou Pty Ltd reasonably
          requires; <br />
          (b) notify Coveryou Pty Ltd of any pending insurer settlement and to
          contact Coveryou Pty Ltd prior to signing any release, deed of
          release, settlement or similar document; and <br />
          (c) not release or purport to release (by signing a form of release or
          otherwise) the Third Party from any of the Hirer’s rights and
          entitlements in respect to the Accident. <br />
          <br />
          <strong />
        </p>
        <p>
          <strong>4.3 D</strong>
          <strong>AMAGED VEHICLE</strong> <br />
          (a) The Hirer agrees to promptly notify Coveryou Pty Ltd if the
          Damaged Vehicle is moved from one location or repairer to another
          location or repairer. <br />
          (b) The Hirer agrees to promptly notify Coveryou Pty Ltd and arrange
          for collection of the Vehicle after receiving: <br />
          (i) notice that the repairs to the Damaged Vehicle have been
          completed; or
          <br />
          (ii) a total loss settlement in respect of the Damaged Vehicle, <br />
          and if the Hirer does not notify Coveryou Pty Ltd within the period
          required (which the Hirer acknowledges and agrees is usually within
          three (3) working days), the Hirer may be liable for the Hire Charges
          incurred thereafter.
          <br />
          (c) The Hirer acknowledges that: <br />
          (i) Coveryou Pty Ltd is an independent company and does not represent
          any insurer or repairer; and <br />
          (ii) Coveryou Pty Ltd does not organise or coordinate nor is it liable
          or responsible for the repair, storage, movement or disposal of the
          Damaged Vehicle (which is the sole responsibility of the owner of the
          Damaged Vehicle). <br />
          <br />
          <strong />
        </p>
        <p>
          <strong>4.4 GST</strong> <br />
          If the Damaged Vehicle is registered for business use or the Hirer is
          registered for GST and may claim an Input Tax Credit for the GST
          component of the Hire Charges, then the Hirer is liable for and must
          pay the GST component of the Hire Charges to Coveryou Pty Ltd and then
          claim it back in the Hirer’s next BAS.
        </p>
        <p>
          <strong />
        </p>
        <br />

        <p>
          <strong>5. W</strong>
          <strong>ARRANTIES</strong>
        </p>
        <br />

        <p>
          <strong>5.1 G</strong>
          <strong>ENERAL</strong>
          <strong>
            {" "}
            <br />
          </strong>
          The Hirer warrants to Coveryou Pty Ltd that: <br />
          (a) the Accident was a result of the negligence of the Third Party;{" "}
          <br />
          (b) to the best of the Hirer’s knowledge, the information the Hirer
          has provided to Coveryou Pty Ltd in connection with this Agreement
          (including in relation to the Damaged Vehicle and the Accident) is
          true, complete and correct; <br />
          (c) at the time of the Accident, the Damaged Vehicle was registered,
          the Hirer regularly drove the Damaged Vehicle and the Hirer held a
          valid and current Australian Driver Provisional (P2) licence for the
          class of the Damaged Vehicle; <br />
          (d) the Hirer has suffered physical inconvenience and loss of amenity
          of use as a result of the Damaged Vehicle being unavailable for their
          use; and
          <br />
          (e) the Hirer has not relied on any promise, representation,
          inducement or conduct material to the entry by the Hirer into the
          Agreement other than as expressly set out in the Agreement with
          respect to any aspect of the hire, the Hire Charges or recovery
          process. <br />
          <br />
        </p>
        <p>
          <strong>5.2 R</strong>
          <strong>IDESHARE VEHICLES</strong>
          <strong>
            {" "}
            <br />
          </strong>
          The Hirer warrants to Coveryou Pty Ltd that: <br />
          (a) the Hirer has informed Coveryou Pty Ltd that the Damaged Vehicle
          was used either exclusively or in part for rideshare purposes (if
          applicable) and that the Hirer intends to use the Vehicle for similar
          purposes; and
          <br />
          (b) the Hirer understands that it is the Hirer’s responsibility to
          insurer the rental vehicle for rideshare, register the Vehicle as a
          commercial passenger vehicle and pay any applicable fee before using
          the Vehicle for rideshare purposes. <br />
          <br />
        </p>
        <p>
          <strong>5.3 R</strong>
          <strong>ELIANCE</strong>
          <strong>
            {" "}
            <br />
          </strong>
          The Hirer acknowledges that it is aware that Coveryou Pty Ltd is
          relying on the warranties given by the Hirer in the Agreement
          (including the warranties in clauses 5.1 and 5.2) in agreeing to
          provide the Vehicle to the Hirer on credit and otherwise in agreeing
          to the obligations in the Agreement and in committing to the recovery
          process.
        </p>
        <br />

        <p>
          <strong>6. A</strong>
          <strong>CKNOWLEDGEMENT, AGREEMENT AND AUTHORISATION</strong>
          <strong />
        </p>
        <br />

        <p>
          By signing below, the Hirer and Coveryou Pty Ltd acknowledge and agree
          that they have each read, understood and agree to the terms set out in
          the Agreement. <br />
          Without limiting the forgoing, by signing this Agreement and the
          Authority to Act the Hirer:
        </p>
        <p>
          Declares and confirms to the best of its knowledge, that the
          information it has provided to Coveryou Pty Ltd in connection with the
          Agreement (including in relation to the Damaged Vehicle and the
          Accident) is true, complete and correct.
        </p>
        <p>
          Authorises Coveryou Pty Ltd, its management service, nominated debt
          recovery agents and legal representatives to recover all Hire Charges
          on their behalf and, requires all monies owed in relation to this car
          hire to be paid to Coveryou Pty Ltd, on the terms and conditions of
          this Agreement and Authority to Act.
        </p>
        <p>
          Agrees to provide the Hirer’s credit card details to Coveryou Pty Ltd
          prior to the commencement of the Hire Period and the Hirer authorises
          Coveryou Pty Ltd to pay the Hire Charges and any Additional Charges
          payable by the Hirer by debiting such amount from the card.; and
        </p>
        <p>
          Agrees the Hirer is liable for and must pay the GST component of the
          Hire Charges to Coveryou Pty Ltd and then claim it back in the Hirer’s
          next BAS if the Damaged Vehicle is registered for business use or the
          Hirer is registered for GST and may claim an Input Tax Credit for the
          GST component of the Hire Charges.
        </p>
      </>
    </Stack>
    <Footer />
  </Stack>
);

export default TermsOfUserPage;
