import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import styles from "./index.module.scss";
import firstContentLogo from "../../assets/images/LandingPage/firstContentLogo.png";
import { useState } from "react";
import useGetScreenType from "../../hooks/useGetScreenType";
import ApplyNowModal from "../../components/CustomizedModal/applyNowModal";
import { LANDING_PAGE_PARAMS } from "../../routers/shared";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ContactUsPage = () => {
    const [isApplyNowModal, setIsApplyNowModal] = useState(false);
    return (
        <>
            <Header/>
            <ApplyNowModal isApplyNowModal={isApplyNowModal} handleClose={() => setIsApplyNowModal(false)} />
            <Stack id={LANDING_PAGE_PARAMS.CONTACT} justifyContent={"center"} alignItems={"center"} className={`${styles.firstContent} fillScreen`} >
                <Stack className={styles.contentWrapper} >
                    <Stack className={styles.desStack}>
                        <h4>Contact Us</h4>
                        <p className='neutrals600' style={{ fontStyle:'normal !important', fontWeight:'500'}}>
                            If you need assistance, our friendly customer service team is available Monday to Friday, 9am to 5pm AEDT.
                        </p>
                        <p className='neutrals600' style={{ fontStyle:'normal !important', fontWeight:'500'}}>
                            Get in touch at <a href='mailto:help@coveryou.com.au'>help@Coveryou.com.au</a> or call <a href='tel:+1300505508'>1300 505 508</a>
                        </p>
                    </Stack>
                </Stack>
                <img src={firstContentLogo} alt='firstContentLogo' className={styles.firstContentLogo} />
            </Stack>
            <Footer/>
        </>
    );
};

export default ContactUsPage;