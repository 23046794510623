import Stack from "@mui/material/Stack";
import styles from "./SixthContent.module.scss";
import useGetScreenType from "../../../hooks/useGetScreenType";
import { LANDING_PAGE_PARAMS } from "../../../routers/shared";
import appImage from "../../../assets/images/LandingPage/app.png";

const SixthContent = () => {
    const screenType = useGetScreenType();
    return (
        <Stack className={`${styles.sixthContent}`} id={LANDING_PAGE_PARAMS.APP} >
            <Stack className={styles.textStack}>
                <h1> Unlock your car {screenType === "PC" && <br />} through the app </h1>
                <big> To get access to our vehicles, you'll require a smartphone, complete a one-time registration, and receive your digital key to activate the car. </big>
            </Stack>
            <Stack className={styles.imageWrapper}>
                <img src={appImage} alt='Mobile App' className={styles.app} />
            </Stack>
        </Stack>
    );
};

export default SixthContent;
