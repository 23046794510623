export const EMPTY_INPUT_HELPER_MSG = "This field cannot be empty";

export const getErrorMsgForEmailInput = (email: string) => {
  if (email.trim().length === 0) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return "Please enter a valid email";
  }
};

export const getErrorMsgForPasswordInput = (
  password: string,
  // In login page only check user have input password
  // this is because firebase reset password only have password strength of length is more than 6
  forLogin?: boolean
) => {
  if (password.length === 0) return EMPTY_INPUT_HELPER_MSG;
  if (forLogin) {
    if (password.trim().length < 5) {
      return "Please input password";
    }
  } else {
    if (
      password.length < 8 ||
      !/\d/.test(password) ||
      !/[!@#$%^&*()_+{}\[\]:;<>,.?~\-]/.test(password)
    ) {
      return "Password must be at least 8 characters long, include at least one digit, and contain at least one special character";
    }
  }
};

function isValidAustralianMobileNumber(number: string): boolean {
  // Remove any spaces from the input
  const cleanedNumber = number.replace(/\s/g, "");

  // Define regular expressions for valid Australian mobile number formats
  // These patterns cover common formats with or without spaces
  const patterns: RegExp[] = [
    /^\+614\d{8}$/, // +614XXXXXXXX
    /^614\d{8}$/, // 614XXXXXXXX
    /^04\d{8}$/, // 04XXXXXXXX
    /^\+612[0-9]\d{8}$/, // +612XXXXXXXX
    /^612[0-9]\d{8}$/, // 612XXXXXXXX
    /^02[0-9]\d{8}$/, // 02XXXXXXXX
  ];

  // Check if the cleaned input matches any of the patterns
  for (const pattern of patterns) {
    if (pattern.test(cleanedNumber)) {
      return true;
    }
  }

  return false;
}

export const getErrorMsgForMobileInput = (number: string) => {
  if (number.trim().length === 0) {
    return EMPTY_INPUT_HELPER_MSG;
  } else if (!/^(\+\d{1,3}[- ]?)?\d{10}$/.test(number)) {
    return "Please enter a valid mobile";
  }
};

export const mobileNoValidator = (number: string) => {
    if (number.trim().length === 0) return "This field cannot be empty.";
    else if (!/^04\d{8}$/.test(number)) return "Please enter a valid Australian mobile number in the format: 0412345678";
    return undefined;
};